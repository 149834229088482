import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo/Seo"
import bolt from "../images/ergsense-bolt.svg"
const StorePage = ({ data }) => {
  const products = data.allWpSimpleProduct.nodes

  return (
    <>
      <Seo title="Store" />
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex items-center">
              <h2 className="text-3xl pt-2 mr-2 font-extrabold tracking-tight text-gray-900">
                Latest Products
              </h2>
              <img src={bolt} width={50} height={50} />
            </div>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-3 md:gap-y-0 lg:gap-x-8">
            {products.map(product => (
              <div key={product.id} className="group relative">
                <div className="w-full h-56 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 lg:h-72 xl:h-80">
                  <GatsbyImage
                    image={getImage(product.image.localFile)}
                    alt={product.imageAlt}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-900">
                  <Link to={`/products/${product.slug}`}>
                    <span className="absolute inset-0" />
                    {product.name}
                  </Link>
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default StorePage

export const query = graphql`
  query {
    allWpSimpleProduct(sort: { fields: menuOrder, order: DESC }) {
      nodes {
        id
        menuOrder
        name
        description
        slug
        image {
          title
          uri
          altText
          localFile {
            absolutePath
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
      }
    }
  }
`
